// Translated
<template lang="pug">
Alert(
  v-if="active"
  :type="type"
  :class="{ '--minimal': minimal }"
  content-class="flex-column"
)
  span {{ capitalizeFirstLetter(message) }}
  button.btn(v-if="hasAction" @click="action()") {{ actionText }}
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { AuthPages } from '@layers/web/utils/auth/auth'
import contexts from '@layers/web/constants/contexts'
import cognitoMixin, { AuthFeedbackMethods } from '@layers/web/mixins/cognito.mixin'

export default defineNuxtComponent({
  mixins: [cognitoMixin],

  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useUserStore, {
      feedback: 'feedback',
    }),

    active () {
      return !!this.feedback
    },

    hasAction () {
      return this.feedback?.action && this.feedback?.actionText
    },

    actionText () {
      return this.feedback?.actionText || ''
    },

    message () {
      return this.feedback?.message || ''
    },

    type () {
      if (this.feedback) {
        return this.feedback?.type || contexts.feedback
      }
      return contexts.info
    },
  },

  methods: {
    ...mapActions(useUserStore, {
      setFeedback: 'SET_FEEDBACK',
    }),

    action () {
      const [action, payload] = this.feedback?.action.split(':') ?? []
      const actionName = payload ? action : this.feedback?.action
      switch (actionName) {
        case AuthFeedbackMethods.login: {
          this.setFeedback(null)
          this.$router.push(this.authLinkTo(AuthPages.login)).catch(() => {})
          break
        }
        case AuthFeedbackMethods.verify: {
          this.setFeedback(null)
          this.$router.push({ path: this.authLinkTo(AuthPages.signup), query: { resendCode: payload } }).catch(() => {})
          break
        }
        case AuthFeedbackMethods.resetPassword: {
          this.setFeedback(null)
          this.$router.push({ path: this.authLinkTo(AuthPages.forgot), query: { resendCode: payload } }).catch(() => {})
          break
        }
      }
    },
  },
})
</script>

<style lang="scss">
.alert.alert-custom.--minimal {
  padding: .5rem .75rem;
  font-size: .9rem;

  .alert-custom-icon {
    display: none !important;
  }
  .alert-custom-content {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
</style>
